<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppNotFounds />
    </template>
    <template #fallback>
      <AppLoading />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
// import AppNotFounds from "@/components/Pages/AppNotFounds";
import AppFooter from "@/components/layouts/AppFooter";
import AppLoading from "@/components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppNotFounds = defineAsyncComponent(() =>
  import("@/components/Pages/AppNotFounds")
);
export default {
  name: "NotFounds",
  components: {
    AppHeader,
    AppNotFounds,
    AppFooter,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>